import React from "react";
import styled from "@emotion/styled";

export default function ExternalNothingToSee() {
  return <Wrapper>Nothing to see here.</Wrapper>;
}

const Wrapper = styled.div`
  margin: 1em;
`;
